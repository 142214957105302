import { FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import ViewUpdate from "../../_smoodi/components/ViewUpdate";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import Manual from '../../_smoodi/components/Manual';
import ChangeLogs from "../../_smoodi/components/ChangeLogs";
import { CreateVersion } from "../../_smoodi/components/CreateVersion";
import ViewVersions from "../../_smoodi/components/ViewVersions";
import { DoRelease } from "../../_smoodi/components/DoRelease";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />

        {/* Lazy Modules */}
        <Route
          path="/create/version/*"
          element={
            <SuspensedView>
              <CreateVersion />
            </SuspensedView>
          }
        />
        <Route
          path="/view/version/*"
          element={
            <SuspensedView>
              <ViewVersions />
            </SuspensedView>
          }
        />

        <Route
          path="/do/release/*"
          element={
            <SuspensedView>
              <DoRelease />
            </SuspensedView>
          }
        />
        <Route
          path="/view/release/*"
          element={
            <SuspensedView>
              <ViewUpdate />
            </SuspensedView>
          }
        />

        {/* Manual PDF  */}
        <Route path="manual" element={<Manual />} />

        {/* Change Log  */}
        <Route path="changelog" element={<ChangeLogs />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
