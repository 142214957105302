import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function abortRelease(
  releaseId: string,
  status: string,
  cancelReason?: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation abortRelease (
                $releaseId: String!
                $status: ReleaseStatus!
                $cancelReason: String!
              ){
            abortRelease(
              abortRelease: {
                releaseId: $releaseId
                status: $status
                cancelReason: $cancelReason
              }
            ) 
          }
        `,
      variables: {
        releaseId,
        status,
        cancelReason,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function abortReleaseMachine(
  machineReleaseId: string,
  status: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation abortReleaseMachine (
                $machineReleaseId: String!
                $status: ReleaseStatus!
              ){
            abortReleaseMachine(
              abortReleaseMachine: {
                machineReleaseId: $machineReleaseId
                status: $status
              }
            ) 
          }
        `,
      variables: {
        machineReleaseId,
        status,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getOtaMachines() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getOtaMachines {
            id
            name
            remark
            serialNumber
            location {
              id
              name
            }
            machineStatus {
              id
              name
            }
            isActive
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getOtaMachines;
}

export async function getMachineStatusList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getMachineStatus{
            id
            name
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getMachineStatus;
}