import React, { Fragment, Suspense, useCallback } from "react";
import { useQueryLoader } from "react-relay";
import DoReleasePage from "./DoReleasePage";
import { getBinaries, getConfigVersions, getRscFiles, getScripts } from "../models/_commonQuery";

export const DoRelease = () => {
  const [queryRefScript, loadQueryScript] = useQueryLoader(getScripts);
  const [queryRefRsc, loadQueryRsc] = useQueryLoader(getRscFiles);
  const [queryRefConfig, loadQueryConfig] = useQueryLoader(getConfigVersions);
  const [queryRefBinaries, loadQueryBinaries] = useQueryLoader(getBinaries);

  const refreshBinary = useCallback(() => {
    const { variablesBinaries }: any = getBinaries;
    loadQueryBinaries(variablesBinaries, { fetchPolicy: "network-only" });
  }, [loadQueryBinaries]);
  const refreshConfig = useCallback(() => {
    const { variablesConfig }: any = getConfigVersions;
    loadQueryConfig(variablesConfig, { fetchPolicy: "network-only" });
  }, [loadQueryConfig]);
  const refreshScript = useCallback(() => {
    const { variablesScript }: any = getScripts;
    loadQueryScript(variablesScript, { fetchPolicy: "network-only" });
  }, [loadQueryScript]);
  const refreshRsc = useCallback(() => {
    const { variablesRsc }: any = getRscFiles;
    loadQueryRsc(variablesRsc, { fetchPolicy: "network-only" });
  }, [loadQueryRsc]);

  return (
    <Suspense>
      <Fragment>
        <DoReleasePage
          queryRefScript={queryRefScript}
          queryRefRsc={queryRefRsc}
          queryRefConfig={queryRefConfig}
          queryRefBinaries={queryRefBinaries}
          getBinaries={getBinaries}
          getConfigVersions={getConfigVersions}
          getRscFiles={getRscFiles}
          getScripts={getScripts}
          refreshBinary={refreshBinary}
          refreshConfig={refreshConfig}
          refreshScript={refreshScript}
          refreshRsc={refreshRsc}
        />
      </Fragment>
    </Suspense>
  );
};