/**
 * @generated SignedSource<<dcacf4cd60523b4eb92a37d6bee87bae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CommonQuery_getOtaMachinesQuery$variables = {};
export type CommonQuery_getOtaMachinesQuery$data = {
  readonly getOtaMachines: ReadonlyArray<{
    readonly id: string;
    readonly location: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly name: string | null;
    readonly remark: string | null;
    readonly serialNumber: string;
  }>;
};
export type CommonQuery_getOtaMachinesQuery = {
  response: CommonQuery_getOtaMachinesQuery$data;
  variables: CommonQuery_getOtaMachinesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getOtaMachines",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remark",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommonQuery_getOtaMachinesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommonQuery_getOtaMachinesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "533fb2d62935d4122914146c0ad20580",
    "id": null,
    "metadata": {},
    "name": "CommonQuery_getOtaMachinesQuery",
    "operationKind": "query",
    "text": "query CommonQuery_getOtaMachinesQuery {\n  getOtaMachines {\n    id\n    name\n    remark\n    serialNumber\n    location {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed29ded0572fcf3303c5fa05ec421a6f";

export default node;
