import { ErrorMessage } from "formik";
import MultiSelect from "./MySelect";
import clsx from "clsx";

export type Option = {
  value: number | string;
  label: string;
};

interface Props {
  refreshScript(): void;
  setFieldValue(key: string, value: any): void;
  setUploadScriptFile(file: any): void;
  onUploadFile(e: any): void;
  uploadScriptFile: any[];
  DeleteSelectFile: any;
  selectScriptResult: any;
  selectScriptFile: string;
  refreshRsc(): void;
  uploadRscFile: any[];
  selectRscFile: Option[];
  errors: any;
  touched: any;
  setUploadRscFile(file: any): void;
  rscFileResult: any;
}

export default function UpdateScriptTypeAndRscFileInputSelectOrUpload(props: Props) {
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
        <label className="form-label required mt-2 ms-2 fs-4 fw-bold">
          Update Script Type
        </label>
        <div
          className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
          onClick={() => props.refreshScript()}
        >
          <i className="fa fa-refresh fs-4 mb-1 spin"></i>
        </div>
        <div className="d-flex mb-3 mt-2">
          <label htmlFor="updateScript_file" className="fs-4">
            <input
              type="file"
              id="updateScript_file"
              onChange={(e)=>{
                props.onUploadFile(e);
                props.setFieldValue("isUpdateFile",true);
              }}
              accept="text/plain"
              placeholder="Upload update script"
              className="d-none"
              multiple
            />
            <span
              className="btn btn-bg-light text-gray-700 fs-4 w-100 mt-2 ms-2 fs-5 px-5 py-3 w-100"
              style={{ cursor: "pointer" }}
            >
              Upload Update Script
            </span>
          </label>
        </div>
        {props.uploadScriptFile.map((data: any) => {
          const { name, content } = data;
          return (
            <span
              className="d-flex align-items-center me-2 mt-3"
              key={`script-files-${name}`}
            >
              <span className="symbol symbol-50px me-6">
                <span className="symbol-label">
                  <i className="far fa-file-alt fs-1 text-gray-700"></i>
                </span>
              </span>

              <span className="d-flex flex-column">
                <span className="fw-bold text-gray-800 fs-5">{name}</span>
                <span
                  className="file-link text-muted text-hover-gray-700 fw-semibold"
                  onClick={() => {
                    props.DeleteSelectFile(content);
                    if(props.uploadScriptFile?.length === 1){
                      props.setFieldValue("isUpdateFile",false);
                    }
                  }}
                >
                  Delete
                </span>
              </span>
            </span>
          );
        })}
        <div className="container mt-3 p-2 border overflow-y-auto rounded">
          <div
            className="overflow-auto list-container fs-5"
            style={{
              maxWidth: "400px",
              maxHeight: "100px",
            }}
          >
            {props.selectScriptResult.length > 0 ? (
              props.selectScriptResult.map((value: any, index: any) => {
                return (
                  <label
                    key={index}
                    className="form-check form-check-custom form-check-solid form-check-sm my-2 d-flex align-items-center fs-6"
                  >
                    <input
                      className="form-check-input ms-2"
                      type="radio"
                      name="selectScriptFile"
                      value={value.url}
                      checked={props.selectScriptFile === value.url}
                      onChange={() =>
                        props.setFieldValue("selectScriptFile", value.url)
                      }
                    />
                    <span
                      className="form-check-label ms-2 fs-6"
                      style={{ cursor: "pointer" }}
                    >
                      {value.fileName}
                    </span>
                  </label>
                );
              })
            ) : (
              <div className="text-muted text-center">No options</div>
            )}
          </div>
        </div>
          <div className="text-danger text-align-top-center mt-1 ms-1">
            <ErrorMessage name="selectScriptFile" />
          </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
        <label className="form-label mt-2 ms-2 fs-4 fw-bold">
          RSC File Type
          <div
            className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
            onClick={() => props.refreshRsc()}
          >
            <i className="fa fa-refresh fs-4 mb-1 spin"></i>
          </div>
        </label>
        <div className="d-flex mb-3 mt-2">
          <label htmlFor="rsc_file" className="fs-4">
            <input
              type="file"
              id="rsc_file"
              onChange={(e)=>{
                props.onUploadFile(e);
                props.setFieldValue("isRscFile",true);
              }}
              placeholder="Add rsc file"
              className="d-none"
              hidden
              multiple
            />
            <span
              className="btn btn-bg-light text-gray-700 fs-4 w-100 mt-2 ms-2 fs-5 px-5 py-3"
              style={{ cursor: "pointer" }}
            >
              Upload RSC file
            </span>
          </label>
        </div>
        {props.uploadRscFile.map((data: any) => {
          const { name, content } = data;
          return (
            <span
              className="d-flex align-items-center me-2 mt-3"
              key={`rsc-files-${name}`}
            >
              <span className="symbol symbol-50px me-6">
                <span className="symbol-label">
                  <i className="far fa-file-alt fs-1 text-gray-700"></i>
                </span>
              </span>

              <span className="d-flex flex-column">
                <span className="fw-bold text-gray-800 fs-5">{name}</span>
                <span
                  className="file-link text-muted text-hover-gray-700 fw-semibold"
                  onClick={() => {
                    props.DeleteSelectFile(content);
                    if(props.uploadRscFile?.length === 1){
                      props.setFieldValue("isRscFile",false);
                    }
                  }}
                >
                  Delete
                </span>
              </span>
            </span>
          );
        })}

        <MultiSelect
          class={clsx("react-select mt-3", {
            "is-invalid":
              Boolean(props.errors.selectRscFile) &&
              props.touched.selectRscFile,
          })}
          title=""
          setSelected={(selected) => {
            props.setFieldValue("selectRscFile", selected);
          }}
          selected={props.selectRscFile}
          options={Array.from(props.rscFileResult).map((value: any) => {
            return {
              value: value.url,
              label: value.fileName,
            };
          })}
        />

        
          <div className="text-danger text-align-top-center mt-1 ms-1">
            <ErrorMessage name="selectRscFile" />
          </div>
      </div>
    </>
  );
}
