import { Fragment, Suspense, useCallback } from "react";
import { getBinaries, getConfigVersions, getRscFiles, getScripts } from "../models/_commonQuery";
import { useQueryLoader } from "react-relay";
import CreateVersionPage from "./CreateVersionPage";

export const CreateVersion = () => {
  const [, loadQueryScript] = useQueryLoader(getScripts);
  const [, loadQueryRsc] = useQueryLoader(getRscFiles);
  const [, loadQueryConfig] = useQueryLoader(getConfigVersions);
  const [, loadQueryBinaries] = useQueryLoader(getBinaries);

  const refreshBinary = useCallback(() => {
    const { variablesBinaries }: any = getBinaries;
    loadQueryBinaries(variablesBinaries, { fetchPolicy: "network-only" });
  }, [loadQueryBinaries]);
  const refreshConfig = useCallback(() => {
    const { variablesConfig }: any = getConfigVersions;
    loadQueryConfig(variablesConfig, { fetchPolicy: "network-only" });
  }, [loadQueryConfig]);
  const refreshScript = useCallback(() => {
    const { variablesScript }: any = getScripts;
    loadQueryScript(variablesScript, { fetchPolicy: "network-only" });
  }, [loadQueryScript]);
  const refreshRsc = useCallback(() => {
    const { variablesRsc }: any = getRscFiles;
    loadQueryRsc(variablesRsc, { fetchPolicy: "network-only" });
  }, [loadQueryRsc]);
  return (
    <Suspense>
      <Fragment>
        <CreateVersionPage
          getBinaries={getBinaries}
          getConfigVersions={getConfigVersions}
          getRscFiles={getRscFiles}
          getScripts={getScripts}
          refreshBinary={refreshBinary}
          refreshConfig={refreshConfig}
          refreshScript={refreshScript}
          refreshRsc={refreshRsc}
        />
      </Fragment>
    </Suspense>
  );
};
