/**
 * @generated SignedSource<<768a8b1b429baf3e94b123fb3e84fdbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CommonQuery_getBinariesQuery$variables = {};
export type CommonQuery_getBinariesQuery$data = {
  readonly getBinaries: ReadonlyArray<{
    readonly fileName: string;
    readonly url: string;
  }>;
};
export type CommonQuery_getBinariesQuery = {
  response: CommonQuery_getBinariesQuery$data;
  variables: CommonQuery_getBinariesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GetFilesOutputObjectType",
    "kind": "LinkedField",
    "name": "getBinaries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fileName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommonQuery_getBinariesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommonQuery_getBinariesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4fda398189557b4b04896748c73d1c05",
    "id": null,
    "metadata": {},
    "name": "CommonQuery_getBinariesQuery",
    "operationKind": "query",
    "text": "query CommonQuery_getBinariesQuery {\n  getBinaries {\n    fileName\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d8682316c05da0e8c699f80a60fa904";

export default node;
