import { graphql } from "babel-plugin-relay/macro";

export const getBinaries = graphql`
  query CommonQuery_getBinariesQuery {
    getBinaries {
      fileName
      url
    }
  }
`;
export const getConfigVersions = graphql`
  query CommonQuery_getConfigVersionQuery {
    getConfigVersion {
      fileName
      url
    }
  }
`;
export const getScripts = graphql`
  query CommonQuery_getScriptsQuery {
    getScripts {
      fileName
      url
    }
  }
`;
export const getRscFiles = graphql`
  query CommonQuery_getRscQuery {
    getRsc {
      fileName
      url
    }
  }
`;

export const getMachines = graphql`
  query CommonQuery_getOtaMachinesQuery {
    getOtaMachines {
      id
      name
      remark
      serialNumber
      location {
        id
        name
      }
    }
  }
`;