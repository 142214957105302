import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateVersionInput {
  versionNo: string;
  launchDateAndTime: string;
  endDateAndTime: string;
  binaryCommitPath: string;
  configVersionPath: string;
  selectRscFile?: string[];
  selectUpdateScript?: string[];
  selectRscFileType?: string;
  selectUpdateScriptType?: string;
  isLatestUpdate?: boolean;
  uploadUpdateScript?: string[];
  uploadRscFile?: string[];
}
export async function createVersion(input: CreateVersionInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation createVersion (
        $input: CreateVersionInput!
        ){
         createVersion(
            createVersionInput: $input
          ) 
      }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}